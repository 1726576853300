import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'

// import Stack from "../components/stack"
// import FourRow from "../components/FourRow"
// import News from "../components/news"

import BrandPortfolio from "../components/BrandPortfolio"


import heroImg from "../images/adjmi/home/black-brand-banner.jpg";

import tw from 'twin.macro' // eslint-disable-line


const IndexPage = () => {

  return (
    <Layout>

      <img src={heroImg} alt="Hero Header" />


      <div tw="max-w-5xl mx-auto px-7 py-12">

        <div tw="font-gibson text-center">
          <strong>Adjmi Apparel Group has designed, produced and distributed apparel to all tiers of retail with acclaimed success.</strong><br /><br /> From its early days as a small importer to its current stature as one of the largest and most reputable companies in the industry, the Adjmi name has become synonymous with high quality fashionable apparel for every product category and age group, from newborns to adults.
        </div>

      </div>

      <BrandPortfolio />

      {/* <FourRow
        img={ 'https://dummyimage.com/767x767' }
        bold={ 'Adjmi Apparel' }
        button={ 'Learn More' }
      />
      <Stack
        img={ 'https://dummyimage.com/1536x300/222d91/6e70b8' }
        title={ 'Adjmi Apparel Group' }
        describe={ "lorem ipsum" }
      />
      <News /> */}
    </Layout>
  )
}

export default IndexPage
