import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro' // eslint-disable-line

const BrandOverviewContainer = styled.div`
    ${tw`pb-16`}
`

const BrandWrap = styled.div`
    ${tw`grid md:grid-cols-1 gap-3 md:gap-8 px-3 md:px-8`}
`

const Brand = styled(Link)`
    ${tw`flex flex-col text-center`}
`

const BrandPortfolio = () => {

  const data = useStaticQuery(graphql`
      query BrandQuery {
        allPrismicBrand(sort: {fields: data___order}) {
          edges {
            node {
              data {
                project_hero_image {
                  url
                }
                title {
                  text
                }
              }
              uid
            }
          }
        }
      }
    `)

  const brandNodes = data.allPrismicBrand.edges

  return (
    <BrandOverviewContainer>
      <BrandWrap>
        {brandNodes.map(brand => { 
          const url = `/brands/${brand.node.uid}`
          const hero = brand.node.data.project_hero_image.url
          const title = brand.node.data.title.text
          return <Brand key={ url } to={ url }><img src={hero} alt='Brand Hero' /><div tw='pt-5'>{title}</div></Brand>
        })}
      </BrandWrap>
    </BrandOverviewContainer>
  )
}

export default BrandPortfolio
